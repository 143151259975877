<template>
  <div class="reset-pwd-container bg-gray-02 auth-bg">
    <div>
      <img
        src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
        class="studio-logo"
        width="91"
        alt="BIGC-STUDIO black Logo"
      />
    </div>
    <div>
      <img
        src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
        class="studio-logo"
        width="91"
        alt="BIGC-STUDIO black Logo"
      />

      <div class="studio-reset-pwd-form">
        <h2>새로운 비밀번호를 설정해주세요</h2>
        <div>
          <input-underline
            name="password"
            type="password"
            label="비밀번호"
            placeholder="비밀번호를 입력해주세요"
            :default-value="state.passwordForm.password"
            :error-message="state.errorMessage.password"
            @updateData="
              (value) => {
                state.passwordForm.password = value;
              }
            "
            @keydown.enter="actions.resetPassword()"
          ></input-underline>
        </div>
        <div>
          <input-underline
            name="passwordConfirm"
            type="password"
            label="비밀번호 확인"
            placeholder="비밀번호를 한번 더 입력해주세요"
            :default-value="state.passwordForm.passwordConfirmation"
            :error-message="state.errorMessage.passwordConfirmation"
            @updateData="
              (value) => {
                state.passwordForm.passwordConfirmation = value;
              }
            "
            @keydown.enter="actions.resetPassword()"
          ></input-underline>
        </div>
        <button
          class="sub-title-s1"
          :class="
            state.isPasswordForm
              ? 'btn-primary text-gray-reverse'
              : 'bg-gray-010 text-gray-third'
          "
          @click="actions.resetPassword()"
        >
          완료
        </button>
      </div>
    </div>
    <teleport to="#teleport">
      <warning-modal
        v-if="state.showExpireTokenInfoModal"
        warning-title="재설정 유효 시간이 지났습니다."
        warning-text="재설정 메일을 다시 보내주세요."
        confirm-text="확인"
        :is-close-btn="false"
        :is-closeable-event="false"
        :show-cancel-button="false"
        @confirm="actions.moveToFindPwd()"
      ></warning-modal>
    </teleport>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import InputUnderline from "../../../components/console/inputs/InputUnderline.vue";
import ApiService from "@/api";
import WarningModal from "../../../components/console/modals/WarningModal";

export default {
  name: "ResetPassword",
  components: {
    InputUnderline,
    WarningModal,
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.token) {
      return next({ name: "auth.findpwd" });
    }
    return next();
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      passwordForm: {
        password: "",
        passwordConfirmation: "",
      },
      errorMessage: {
        password: "",
        passwordConfirmation: "",
      },
      isPasswordForm: computed(() => {
        let isPassword = state.passwordForm.password.trim().length > 5;
        let isPasswordConfirmation =
          state.passwordForm.passwordConfirmation.trim().length > 5;
        return isPassword && isPasswordConfirmation;
      }),
      showExpireTokenInfoModal: false,
    });

    const actions = {
      resetPassword: async () => {
        let password = state.passwordForm.password.trim();
        const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

        let existAlphabet = /[a-zA-Z]/.test(password);
        let existNumber = /\d/.test(password);
        let existSpecialCharacter = specialChars.test(password);
        let validateCount = 0;

        if (existAlphabet) {
          validateCount += 1;
        }
        if (existNumber) {
          validateCount += 1;
        }
        if (existSpecialCharacter) {
          validateCount += 1;
        }

        if (password.length < 6 || validateCount < 2) {
          return (state.errorMessage.password =
            "6자리 이상의 영문, 숫자, 특수문자를 2가지 이상 조합해주세요.");
        }

        state.passwordForm.password.trim();

        if (
          state.passwordForm.password !==
          state.passwordForm.passwordConfirmation
        ) {
          return (state.errorMessage.passwordConfirmation =
            "비밀번호와 비밀번호 확인이 일치하지 않습니다.");
        }

        const payload = {
          password: state.passwordForm.password,
          token: route.query.token,
        };

        ApiService.postResetPassword(payload).then(async (res) => {
          if (res.data.success) {
            await router.push({
              name: "console.home",
            });
          } else {
            state.showExpireTokenInfoModal = true;
          }
        });
      },
      moveToFindPwd: () => {
        router.push({ name: "auth.findpwd" });
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
